import { ConnectError } from "@connectrpc/connect";
import { closeWindow } from "@pocketsign/in-app-sdk";
import * as Sentry from "@sentry/react";
import {
  CatchBoundary,
  type ErrorComponentProps,
} from "@tanstack/react-router";
import { useCallback } from "react";
import { css } from "../../styled-system/css";
import { extractConnectErrorMessage } from "../libs/connect";
import { AppError } from "../libs/errors";
import { sdk } from "../libs/sdk";

type Props = {
  children: React.ReactNode;
};

function ErrorPage({ error }: ErrorComponentProps) {
  const onClickClose = useCallback(() => {
    try {
      closeWindow(sdk);
    } catch (e) {
      console.error(e);
      window.alert("アプリケーションを再起動してください");
    }
  }, []);

  const message =
    error instanceof ConnectError
      ? extractConnectErrorMessage(error)
      : error.message;

  return (
    <div
      className={css({
        bg: "background.background",
        minH: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      <section
        className={css({
          bg: "white",
          borderRadius: "16px",
          m: "24px",
        })}
      >
        <div
          className={css({
            p: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "16px",
          })}
        >
          <h2
            className={css({
              fontSize: "20px",
              fontWeight: "bold",
              color: "text.primary",
            })}
          >
            エラーが発生しました
          </h2>
          <p
            className={css({
              fontSize: "16px",
              color: "text.secondary",
              lineHeight: "1.5",
            })}
          >
            {message}
          </p>

          <button
            type="button"
            onClick={onClickClose}
            className={css({
              bg: "surface.accentPrimary",
              color: "white",
              w: "full",
              mt: "4",
              rounded: "xl",
              p: "2",
            })}
          >
            閉じる
          </button>
        </div>
      </section>
    </div>
  );
}

export function ErrorBoundary({ children }: Props) {
  return (
    <CatchBoundary
      getResetKey={() => "reset"}
      onCatch={(error) => {
        console.error("Caught error:", error);

        // セッションストレージの問題を取り除くためクリア
        console.log("clear sessionStorage");
        sessionStorage.clear();

        // AppErrorでskipSentryがtrueの場合は送信しない
        if (error instanceof AppError && error.skipSentry) {
          console.log("Skipping Sentry for error:", error.code);
          return;
        }

        Sentry.captureException(error);
      }}
      errorComponent={ErrorPage}
    >
      {children}
    </CatchBoundary>
  );
}
