import { getAppEnv, isMockEnv } from "./libs/utils";

export const readResourceIds = [
  "50f423aa-8ea8-4385-81a1-a7d8da1c4939", // 氏名
  "5419c333-2863-4d57-9272-5f6c6c8989ec", // 住所
  "5ac941bf-88f3-47e3-b2e7-3bb0522aa14b", // 性別
  "612c6a3c-212a-402e-8c89-792a4b3e7889", // 生年月日
  "32df0061-de96-49a6-99d3-764497e64ed6", // 市区町村までの住所
];

export const cityAddressResourceId = "32df0061-de96-49a6-99d3-764497e64ed6";

export const MIYAGI_BOUSAI_RESOURCE_ID = import.meta.env
  .VITE_MIYAGI_BOUSAI_RESOURCE_ID as string;

export const MIYAGI_BOUSAI_SERVICE_ID = import.meta.env
  .VITE_MIYAGI_BOUSAI_SERVICE_ID as string;

export function getProfileResourceIds(tenantSlug: string) {
  const profileResourceIds =
    tenantSlug === "miyagi"
      ? [...readResourceIds, MIYAGI_BOUSAI_RESOURCE_ID]
      : readResourceIds;

  return profileResourceIds;
}

export function getSubscriptionIdResourceId(tenantSlug: string) {
  const mock = isMockEnv();
  const appEnv = getAppEnv();

  // prod/mockはモック環境も用意する。一時的にモック環境のサービスIDが非モックとなる
  if (appEnv === "prod/mock" && mock) {
    if (tenantSlug === "miyagi") {
      return import.meta.env.VITE_MIYAGI_PPID_RESOURCE_ID as string;
    }
    if (tenantSlug === "marumori") {
      return import.meta.env.VITE_MARUMORI_PPID_RESOURCE_ID as string;
    }
  }
  if (appEnv === "prod/mock") {
    if (tenantSlug === "miyagi") {
      return import.meta.env.VITE_MIYAGI_MOCK_PPID_RESOURCE_ID as string;
    }
    if (tenantSlug === "marumori") {
      return import.meta.env.VITE_MARUMORI_MOCK_PPID_RESOURCE_ID as string;
    }
  }

  if (tenantSlug === "demo") {
    return import.meta.env.VITE_DEMO_PPID_RESOURCE_ID as string;
  }
  if (tenantSlug === "miyagi") {
    return import.meta.env.VITE_MIYAGI_PPID_RESOURCE_ID as string;
  }
  if (tenantSlug === "marumori") {
    return import.meta.env.VITE_MARUMORI_PPID_RESOURCE_ID as string;
  }
  throw new Error("Invalid tenant slug");
}
