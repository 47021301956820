import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import type {
  Store,
  Tenant,
} from "schema/gen/es/chiikipoint/model/v2/model_pb";
import { css } from "../../../styled-system/css";
import Header from "../../components/header";
import { createCushionClient } from "../../libs/client";
import { initSDK } from "../../libs/sdk";
import { getAppEnv } from "../../libs/utils";

export const Route = createFileRoute("/s/$storeId")({
  loader: AppLoader,
  component: AppSelector,
});

const serviceIdConfig = {
  "dev/mock": "b261957c-16ca-4faf-b630-5ef9342fca8f",
  "prod/mock": "f5d34ecd-1819-4172-aecd-8cfd113ce7d9",
  // "prod/live": "b261957c-16ca-4faf-b630-5ef9342fca8f",
} as const;

function getServiceId() {
  const appEnv = getAppEnv();
  if (!(appEnv in serviceIdConfig)) {
    throw new Error(`Invalid appEnv: ${appEnv}`);
  }
  return serviceIdConfig[appEnv as keyof typeof serviceIdConfig];
}

function AppLoader({ params: { storeId } }: { params: { storeId?: string } }) {
  if (!storeId) {
    throw new Error("storeId is required");
  }
  const client = createCushionClient({ mock: true });

  return { client, storeId };
}

function AppSelector() {
  const { storeId, client } = Route.useLoaderData();
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [store, setStore] = useState<Store | null>(null);
  const [selectedTenantId, setSelectedTenantId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    client.getAvailableTenants({ storeId }).then((res) => {
      setTenants(res.tenants);
      setStore(res.store ?? null);
      setLoading(false);
    });
  }, [client, storeId]);

  useEffect(() => {
    initSDK(getServiceId());
  }, []);

  if (loading) {
    return (
      <>
        <Header title="ポイントアプリ選択" />
        <div
          className={css({
            bg: "background.background",
            minH: "100vh",
            pt: "48px",
            px: "24px",
            pb: "24px",
          })}
        >
          <div
            className={css({
              mt: "24px",
              height: "24px",
              bg: "surface.tertiary",
              borderRadius: "4px",
              animation: "shine 1.5s infinite linear",
              backgroundImage:
                "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
              backgroundSize: "200% 100%",
              width: "80%",
              mx: "auto",
            })}
          />

          <div
            className={css({
              mt: "32px",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            })}
          >
            <div
              className={css({
                height: "20px",
                width: "30%",
                bg: "surface.tertiary",
                borderRadius: "4px",
                animation: "shine 1.5s infinite linear",
                backgroundImage:
                  "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
                backgroundSize: "200% 100%",
              })}
            />

            <div
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "16px",
                border: "1px solid",
                borderColor: "border.primary",
                borderRadius: "12px",
                p: "16px",
              })}
            >
              <div
                className={css({
                  width: "32px",
                  height: "32px",
                  bg: "surface.tertiary",
                  borderRadius: "4px",
                  animation: "shine 1.5s infinite linear",
                  backgroundImage:
                    "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
                  backgroundSize: "200% 100%",
                })}
              />
              <div
                className={css({
                  height: "20px",
                  width: "40%",
                  bg: "surface.tertiary",
                  borderRadius: "4px",
                  animation: "shine 1.5s infinite linear",
                  backgroundImage:
                    "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
                  backgroundSize: "200% 100%",
                })}
              />
            </div>
          </div>

          <div
            className={css({
              mt: "32px",
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
            })}
          >
            {[1, 2].map((i) => (
              <div
                key={i}
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "8px",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "border.primary",
                  borderRadius: "16px",
                  p: "16px",
                  bg: "white",
                })}
              >
                <div
                  className={css({
                    width: "92px",
                    height: "92px",
                    bg: "surface.tertiary",
                    borderRadius: "16px",
                    animation: "shine 1.5s infinite linear",
                    backgroundImage:
                      "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
                    backgroundSize: "200% 100%",
                  })}
                />
                <div
                  className={css({
                    height: "20px",
                    width: "60%",
                    bg: "surface.tertiary",
                    borderRadius: "4px",
                    animation: "shine 1.5s infinite linear",
                    backgroundImage:
                      "linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)",
                    backgroundSize: "200% 100%",
                  })}
                />
              </div>
            ))}
          </div>

          <div
            className={css({
              height: "44px",
              bg: "surface.disable",
              w: "100%",
              borderRadius: "12px",
              mt: "32px",
            })}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Header title="ポイントアプリ選択" />
      <div
        className={css({
          bg: "background.background",
          minH: "100vh",
          pt: "48px",
          px: "24px",
          pb: "24px",
        })}
      >
        <div
          className={css({
            mt: "24px",
            fontSize: "24px",
            textAlign: "center",
          })}
        >
          どちらのポイントアプリで
          <br />
          支払いますか？
        </div>

        {store && (
          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              mt: "32px",
            })}
          >
            <div
              className={css({
                color: "text.secondary",
              })}
            >
              お支払い店舗
            </div>

            <div
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "16px",
                border: "1px solid",
                borderColor: "border.primary",
                borderRadius: "12px",
                p: "16px",
              })}
            >
              <div
                className={css({
                  width: "32px",
                })}
              >
                <img
                  src={store.iconUrl}
                  alt={store.name}
                  className={css({
                    w: "100%",
                  })}
                />
              </div>
              <div
                className={css({
                  color: "text.primary",
                })}
              >
                {store.name}
              </div>
            </div>
          </div>
        )}

        <div
          className={css({
            mt: "32px",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
          })}
        >
          {tenants.map((tenant) => {
            const isSelected = selectedTenantId === tenant.id;
            return (
              <button
                key={tenant.id}
                onClick={() => setSelectedTenantId(tenant.id)}
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "8px",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: isSelected
                    ? "surface.accentPrimary"
                    : "border.primary",
                  borderRadius: "16px",
                  p: "16px",
                  bg: "white",
                  width: "100%",
                  cursor: "pointer",
                  _hover: {
                    borderColor: "surface.accentPrimary",
                  },
                })}
                type="button"
              >
                <div
                  className={css({
                    width: "92px",
                    height: "92px",
                  })}
                >
                  <img
                    src={tenant.iconUrl}
                    alt={tenant.name}
                    className={css({
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: "16px",
                    })}
                  />
                </div>
                <div>{tenant.name}</div>
              </button>
            );
          })}
        </div>

        <button
          className={css({
            bg: selectedTenantId ? "surface.accentPrimary" : "surface.disable",
            w: "100%",
            p: "12px",
            borderRadius: "12px",
            color: selectedTenantId ? "white" : "text.disable",
            mt: "32px",
            cursor: selectedTenantId ? "pointer" : "not-allowed",
          })}
          type="button"
          disabled={!selectedTenantId}
        >
          決定
        </button>
      </div>
    </>
  );
}
