import { createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import { toast } from "react-hot-toast";
import ContentCopy from "~icons/material-symbols/content-copy-outline";
import StoreFront from "~icons/material-symbols/storefront-outline";
import { css } from "../../../../styled-system/css";
import Header from "../../../components/header";

export const Route = createFileRoute("/_authed/stores/$storeId")({
  loader: async ({ context: { client }, params: { storeId } }) => {
    const storeResponse = await client.getStore({ storeId });
    if (!storeResponse.store) {
      throw redirect({ to: "/stores" });
    }
    return {
      store: storeResponse.store,
      companyName: storeResponse.companyName,
    };
  },
  component: StoreDetail,
});

function StoreDetail() {
  const { store, companyName } = Route.useLoaderData();
  const navigate = useNavigate();

  const onClickBack = () => {
    navigate({ to: "/stores" });
  };

  return (
    <>
      <Header title="店舗詳細" onClickBack={onClickBack} />

      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          py: "32px",
          px: "16px",
        })}
      >
        <div
          className={css({
            display: "flex",
            gap: "16px",
          })}
        >
          <div
            className={css({
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bg: "white",
              rounded: "full",
              width: "48px",
              height: "48px",
              p: "12px",
            })}
          >
            <StoreFront
              className={css({
                w: "full",
                h: "full",
              })}
            />
          </div>

          <div>
            <h1
              className={css({
                color: "text.primary",
                fontSize: "20px",
                fontWeight: 600,
              })}
            >
              {store.name}
            </h1>
            <div
              className={css({
                color: "text.secondary",
                fontSize: "14px",
                fontWeight: 400,
              })}
            >
              コンビニ
            </div>
          </div>
        </div>

        <div
          className={css({
            bg: "white",
            px: "16px",
            rounded: "16px",
          })}
        >
          <div
            className={css({
              display: "flex",
              gap: "8px",
              borderBottom: "1px solid",
              borderColor: "border.primary",
              py: "16px",
            })}
          >
            <h4
              className={css({
                w: "80px",
                color: "text.primary",
                display: "flex",
                alignItems: "center",
                flexShrink: 0,
              })}
            >
              住所
            </h4>
            <p
              className={css({
                color: "text.secondary",
              })}
            >
              {store.fullAddress}
            </p>
            <button
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(store.fullAddress ?? "");
                toast.success("住所をコピーしました");
              }}
            >
              <ContentCopy
                className={css({
                  w: "24px",
                  h: "24px",
                  color: "text.secondary",
                })}
              />
            </button>
          </div>
          <div
            className={css({
              display: "flex",
              gap: "8px",
              py: "16px",
            })}
          >
            <h4
              className={css({
                w: "80px",
                color: "text.primary",
                display: "flex",
                alignItems: "center",
                flexShrink: 0,
              })}
            >
              事業者名
            </h4>
            <p
              className={css({
                color: "text.secondary",
              })}
            >
              {companyName}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
