import {
  GeolocationResources,
  MergedSourceResources,
  PushNotificationResourceId,
  type SDKInstance,
  createApiBackend,
  createAppBackend,
  createSDKInstance,
  onError,
  readResourceRaw,
  requestPermission,
} from "@pocketsign/in-app-sdk";
import * as Sentry from "@sentry/react";
import { getProfileResourceIds, getSubscriptionIdResourceId } from "../config";
import type { TenantWithFeature } from "./connect";
import { AppError, ERROR_CODES } from "./errors";
export let sdk: SDKInstance;

export async function initSDK(serviceId: string) {
  if (sdk) {
    return;
  }

  const backend =
    import.meta.env.VITE_P8N_BACKEND_MODE === "API"
      ? createApiBackend({
          accessToken: import.meta.env.VITE_P8N_API_ACCESS_TOKEN,
          baseUrl: "https://internal.core.mock.p8n.dev",
        })
      : createAppBackend();

  sdk = await createSDKInstance({
    serviceId,
    backend,
  });

  onError(sdk, (type, message, error) => {
    if (error instanceof Error) {
      Sentry.captureException(error);
    } else {
      Sentry.captureMessage(`${type}: ${message}`, {
        level: "error",
      });
    }
  });
}

export async function requestPermissions(tenant: TenantWithFeature) {
  const profileResourceIds = getProfileResourceIds(tenant.slug);
  const subscriptionIdResourceId = getSubscriptionIdResourceId(tenant.slug);

  const permission = await requestPermission(sdk, [
    {
      resourceId: PushNotificationResourceId,
      verbs: [{ verb: "invoke", term: "always" }],
    },
    {
      resourceId: GeolocationResources.normalAccuracy,
      verbs: [{ verb: "invoke", term: "always" }],
    },
    {
      resourceId: subscriptionIdResourceId,
      verbs: [{ verb: "write" as const, term: "always" as const }],
    },
    ...profileResourceIds.map((resourceId) => ({
      resourceId,
      verbs: [{ verb: "read" as const, term: "always" as const }],
    })),
  ]);

  if (permission.result === "cancel" || permission.result === "disagree") {
    throw new AppError(ERROR_CODES.SDK_PERMISSION_NOT_ACCEPTED, undefined, {
      skipSentry: true,
    });
  }

  if (permission.result === "error") {
    throw new AppError(ERROR_CODES.SDK_PERMISSION_ERROR, undefined, {
      sdkErrorNo: permission.errno,
    });
  }

  return permission;
}

export async function getCityAddressWithCache(): Promise<string | null> {
  const sessionKey = "city-address";
  const cache = sessionStorage.getItem(sessionKey);

  if (cache) {
    return cache;
  }

  const response = await readResourceRaw(sdk, {
    resourceId: MergedSourceResources.cityAddress,
  });

  if (response.result !== "success") {
    return null;
  }

  if (!response.value) {
    return null;
  }

  const cityAddress = JSON.parse(response.value) as string;

  sessionStorage.setItem(sessionKey, cityAddress);

  return cityAddress;
}
