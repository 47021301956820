/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthedImport } from './routes/_authed'
import { Route as ClosedIndexImport } from './routes/closed/index'
import { Route as AuthedIndexImport } from './routes/_authed/index'
import { Route as SStoreIdImport } from './routes/s/$storeId'
import { Route as AuthedAboutCampaignImport } from './routes/_authed/about-campaign'
import { Route as AuthedTransactionsIndexImport } from './routes/_authed/transactions/index'
import { Route as AuthedStoresIndexImport } from './routes/_authed/stores/index'
import { Route as AuthedInvitationIndexImport } from './routes/_authed/invitation/index'
import { Route as AuthedIIndexImport } from './routes/_authed/i/index'
import { Route as AuthedTransactionsGroupIdImport } from './routes/_authed/transactions/$groupId'
import { Route as AuthedStoresStoreIdImport } from './routes/_authed/stores/$storeId'
import { Route as AuthedPayStoreIdImport } from './routes/_authed/pay/$storeId'
import { Route as AuthedLotteryResultImport } from './routes/_authed/lottery/result'
import { Route as AuthedLotteryProcessingImport } from './routes/_authed/lottery/processing'
import { Route as AuthedLotteryApplyImport } from './routes/_authed/lottery/apply'
import { Route as AuthedInvitationDetailImport } from './routes/_authed/invitation/detail'
import { Route as AuthedInvitationApplyImport } from './routes/_authed/invitation/apply'
import { Route as AuthedLotteryBousaiMockImport } from './routes/_authed/lottery/bousai.mock'
import { Route as AuthedLotteryBousaiCallbackImport } from './routes/_authed/lottery/bousai.callback'

// Create/Update Routes

const AuthedRoute = AuthedImport.update({
  id: '/_authed',
  getParentRoute: () => rootRoute,
} as any)

const ClosedIndexRoute = ClosedIndexImport.update({
  id: '/closed/',
  path: '/closed/',
  getParentRoute: () => rootRoute,
} as any)

const AuthedIndexRoute = AuthedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthedRoute,
} as any)

const SStoreIdRoute = SStoreIdImport.update({
  id: '/s/$storeId',
  path: '/s/$storeId',
  getParentRoute: () => rootRoute,
} as any)

const AuthedAboutCampaignRoute = AuthedAboutCampaignImport.update({
  id: '/about-campaign',
  path: '/about-campaign',
  getParentRoute: () => AuthedRoute,
} as any)

const AuthedTransactionsIndexRoute = AuthedTransactionsIndexImport.update({
  id: '/transactions/',
  path: '/transactions/',
  getParentRoute: () => AuthedRoute,
} as any)

const AuthedStoresIndexRoute = AuthedStoresIndexImport.update({
  id: '/stores/',
  path: '/stores/',
  getParentRoute: () => AuthedRoute,
} as any)

const AuthedInvitationIndexRoute = AuthedInvitationIndexImport.update({
  id: '/invitation/',
  path: '/invitation/',
  getParentRoute: () => AuthedRoute,
} as any)

const AuthedIIndexRoute = AuthedIIndexImport.update({
  id: '/i/',
  path: '/i/',
  getParentRoute: () => AuthedRoute,
} as any)

const AuthedTransactionsGroupIdRoute = AuthedTransactionsGroupIdImport.update({
  id: '/transactions/$groupId',
  path: '/transactions/$groupId',
  getParentRoute: () => AuthedRoute,
} as any)

const AuthedStoresStoreIdRoute = AuthedStoresStoreIdImport.update({
  id: '/stores/$storeId',
  path: '/stores/$storeId',
  getParentRoute: () => AuthedRoute,
} as any)

const AuthedPayStoreIdRoute = AuthedPayStoreIdImport.update({
  id: '/pay/$storeId',
  path: '/pay/$storeId',
  getParentRoute: () => AuthedRoute,
} as any)

const AuthedLotteryResultRoute = AuthedLotteryResultImport.update({
  id: '/lottery/result',
  path: '/lottery/result',
  getParentRoute: () => AuthedRoute,
} as any)

const AuthedLotteryProcessingRoute = AuthedLotteryProcessingImport.update({
  id: '/lottery/processing',
  path: '/lottery/processing',
  getParentRoute: () => AuthedRoute,
} as any)

const AuthedLotteryApplyRoute = AuthedLotteryApplyImport.update({
  id: '/lottery/apply',
  path: '/lottery/apply',
  getParentRoute: () => AuthedRoute,
} as any)

const AuthedInvitationDetailRoute = AuthedInvitationDetailImport.update({
  id: '/invitation/detail',
  path: '/invitation/detail',
  getParentRoute: () => AuthedRoute,
} as any)

const AuthedInvitationApplyRoute = AuthedInvitationApplyImport.update({
  id: '/invitation/apply',
  path: '/invitation/apply',
  getParentRoute: () => AuthedRoute,
} as any)

const AuthedLotteryBousaiMockRoute = AuthedLotteryBousaiMockImport.update({
  id: '/lottery/bousai/mock',
  path: '/lottery/bousai/mock',
  getParentRoute: () => AuthedRoute,
} as any)

const AuthedLotteryBousaiCallbackRoute =
  AuthedLotteryBousaiCallbackImport.update({
    id: '/lottery/bousai/callback',
    path: '/lottery/bousai/callback',
    getParentRoute: () => AuthedRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authed': {
      id: '/_authed'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthedImport
      parentRoute: typeof rootRoute
    }
    '/_authed/about-campaign': {
      id: '/_authed/about-campaign'
      path: '/about-campaign'
      fullPath: '/about-campaign'
      preLoaderRoute: typeof AuthedAboutCampaignImport
      parentRoute: typeof AuthedImport
    }
    '/s/$storeId': {
      id: '/s/$storeId'
      path: '/s/$storeId'
      fullPath: '/s/$storeId'
      preLoaderRoute: typeof SStoreIdImport
      parentRoute: typeof rootRoute
    }
    '/_authed/': {
      id: '/_authed/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthedIndexImport
      parentRoute: typeof AuthedImport
    }
    '/closed/': {
      id: '/closed/'
      path: '/closed'
      fullPath: '/closed'
      preLoaderRoute: typeof ClosedIndexImport
      parentRoute: typeof rootRoute
    }
    '/_authed/invitation/apply': {
      id: '/_authed/invitation/apply'
      path: '/invitation/apply'
      fullPath: '/invitation/apply'
      preLoaderRoute: typeof AuthedInvitationApplyImport
      parentRoute: typeof AuthedImport
    }
    '/_authed/invitation/detail': {
      id: '/_authed/invitation/detail'
      path: '/invitation/detail'
      fullPath: '/invitation/detail'
      preLoaderRoute: typeof AuthedInvitationDetailImport
      parentRoute: typeof AuthedImport
    }
    '/_authed/lottery/apply': {
      id: '/_authed/lottery/apply'
      path: '/lottery/apply'
      fullPath: '/lottery/apply'
      preLoaderRoute: typeof AuthedLotteryApplyImport
      parentRoute: typeof AuthedImport
    }
    '/_authed/lottery/processing': {
      id: '/_authed/lottery/processing'
      path: '/lottery/processing'
      fullPath: '/lottery/processing'
      preLoaderRoute: typeof AuthedLotteryProcessingImport
      parentRoute: typeof AuthedImport
    }
    '/_authed/lottery/result': {
      id: '/_authed/lottery/result'
      path: '/lottery/result'
      fullPath: '/lottery/result'
      preLoaderRoute: typeof AuthedLotteryResultImport
      parentRoute: typeof AuthedImport
    }
    '/_authed/pay/$storeId': {
      id: '/_authed/pay/$storeId'
      path: '/pay/$storeId'
      fullPath: '/pay/$storeId'
      preLoaderRoute: typeof AuthedPayStoreIdImport
      parentRoute: typeof AuthedImport
    }
    '/_authed/stores/$storeId': {
      id: '/_authed/stores/$storeId'
      path: '/stores/$storeId'
      fullPath: '/stores/$storeId'
      preLoaderRoute: typeof AuthedStoresStoreIdImport
      parentRoute: typeof AuthedImport
    }
    '/_authed/transactions/$groupId': {
      id: '/_authed/transactions/$groupId'
      path: '/transactions/$groupId'
      fullPath: '/transactions/$groupId'
      preLoaderRoute: typeof AuthedTransactionsGroupIdImport
      parentRoute: typeof AuthedImport
    }
    '/_authed/i/': {
      id: '/_authed/i/'
      path: '/i'
      fullPath: '/i'
      preLoaderRoute: typeof AuthedIIndexImport
      parentRoute: typeof AuthedImport
    }
    '/_authed/invitation/': {
      id: '/_authed/invitation/'
      path: '/invitation'
      fullPath: '/invitation'
      preLoaderRoute: typeof AuthedInvitationIndexImport
      parentRoute: typeof AuthedImport
    }
    '/_authed/stores/': {
      id: '/_authed/stores/'
      path: '/stores'
      fullPath: '/stores'
      preLoaderRoute: typeof AuthedStoresIndexImport
      parentRoute: typeof AuthedImport
    }
    '/_authed/transactions/': {
      id: '/_authed/transactions/'
      path: '/transactions'
      fullPath: '/transactions'
      preLoaderRoute: typeof AuthedTransactionsIndexImport
      parentRoute: typeof AuthedImport
    }
    '/_authed/lottery/bousai/callback': {
      id: '/_authed/lottery/bousai/callback'
      path: '/lottery/bousai/callback'
      fullPath: '/lottery/bousai/callback'
      preLoaderRoute: typeof AuthedLotteryBousaiCallbackImport
      parentRoute: typeof AuthedImport
    }
    '/_authed/lottery/bousai/mock': {
      id: '/_authed/lottery/bousai/mock'
      path: '/lottery/bousai/mock'
      fullPath: '/lottery/bousai/mock'
      preLoaderRoute: typeof AuthedLotteryBousaiMockImport
      parentRoute: typeof AuthedImport
    }
  }
}

// Create and export the route tree

interface AuthedRouteChildren {
  AuthedAboutCampaignRoute: typeof AuthedAboutCampaignRoute
  AuthedIndexRoute: typeof AuthedIndexRoute
  AuthedInvitationApplyRoute: typeof AuthedInvitationApplyRoute
  AuthedInvitationDetailRoute: typeof AuthedInvitationDetailRoute
  AuthedLotteryApplyRoute: typeof AuthedLotteryApplyRoute
  AuthedLotteryProcessingRoute: typeof AuthedLotteryProcessingRoute
  AuthedLotteryResultRoute: typeof AuthedLotteryResultRoute
  AuthedPayStoreIdRoute: typeof AuthedPayStoreIdRoute
  AuthedStoresStoreIdRoute: typeof AuthedStoresStoreIdRoute
  AuthedTransactionsGroupIdRoute: typeof AuthedTransactionsGroupIdRoute
  AuthedIIndexRoute: typeof AuthedIIndexRoute
  AuthedInvitationIndexRoute: typeof AuthedInvitationIndexRoute
  AuthedStoresIndexRoute: typeof AuthedStoresIndexRoute
  AuthedTransactionsIndexRoute: typeof AuthedTransactionsIndexRoute
  AuthedLotteryBousaiCallbackRoute: typeof AuthedLotteryBousaiCallbackRoute
  AuthedLotteryBousaiMockRoute: typeof AuthedLotteryBousaiMockRoute
}

const AuthedRouteChildren: AuthedRouteChildren = {
  AuthedAboutCampaignRoute: AuthedAboutCampaignRoute,
  AuthedIndexRoute: AuthedIndexRoute,
  AuthedInvitationApplyRoute: AuthedInvitationApplyRoute,
  AuthedInvitationDetailRoute: AuthedInvitationDetailRoute,
  AuthedLotteryApplyRoute: AuthedLotteryApplyRoute,
  AuthedLotteryProcessingRoute: AuthedLotteryProcessingRoute,
  AuthedLotteryResultRoute: AuthedLotteryResultRoute,
  AuthedPayStoreIdRoute: AuthedPayStoreIdRoute,
  AuthedStoresStoreIdRoute: AuthedStoresStoreIdRoute,
  AuthedTransactionsGroupIdRoute: AuthedTransactionsGroupIdRoute,
  AuthedIIndexRoute: AuthedIIndexRoute,
  AuthedInvitationIndexRoute: AuthedInvitationIndexRoute,
  AuthedStoresIndexRoute: AuthedStoresIndexRoute,
  AuthedTransactionsIndexRoute: AuthedTransactionsIndexRoute,
  AuthedLotteryBousaiCallbackRoute: AuthedLotteryBousaiCallbackRoute,
  AuthedLotteryBousaiMockRoute: AuthedLotteryBousaiMockRoute,
}

const AuthedRouteWithChildren =
  AuthedRoute._addFileChildren(AuthedRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthedRouteWithChildren
  '/about-campaign': typeof AuthedAboutCampaignRoute
  '/s/$storeId': typeof SStoreIdRoute
  '/': typeof AuthedIndexRoute
  '/closed': typeof ClosedIndexRoute
  '/invitation/apply': typeof AuthedInvitationApplyRoute
  '/invitation/detail': typeof AuthedInvitationDetailRoute
  '/lottery/apply': typeof AuthedLotteryApplyRoute
  '/lottery/processing': typeof AuthedLotteryProcessingRoute
  '/lottery/result': typeof AuthedLotteryResultRoute
  '/pay/$storeId': typeof AuthedPayStoreIdRoute
  '/stores/$storeId': typeof AuthedStoresStoreIdRoute
  '/transactions/$groupId': typeof AuthedTransactionsGroupIdRoute
  '/i': typeof AuthedIIndexRoute
  '/invitation': typeof AuthedInvitationIndexRoute
  '/stores': typeof AuthedStoresIndexRoute
  '/transactions': typeof AuthedTransactionsIndexRoute
  '/lottery/bousai/callback': typeof AuthedLotteryBousaiCallbackRoute
  '/lottery/bousai/mock': typeof AuthedLotteryBousaiMockRoute
}

export interface FileRoutesByTo {
  '/about-campaign': typeof AuthedAboutCampaignRoute
  '/s/$storeId': typeof SStoreIdRoute
  '/': typeof AuthedIndexRoute
  '/closed': typeof ClosedIndexRoute
  '/invitation/apply': typeof AuthedInvitationApplyRoute
  '/invitation/detail': typeof AuthedInvitationDetailRoute
  '/lottery/apply': typeof AuthedLotteryApplyRoute
  '/lottery/processing': typeof AuthedLotteryProcessingRoute
  '/lottery/result': typeof AuthedLotteryResultRoute
  '/pay/$storeId': typeof AuthedPayStoreIdRoute
  '/stores/$storeId': typeof AuthedStoresStoreIdRoute
  '/transactions/$groupId': typeof AuthedTransactionsGroupIdRoute
  '/i': typeof AuthedIIndexRoute
  '/invitation': typeof AuthedInvitationIndexRoute
  '/stores': typeof AuthedStoresIndexRoute
  '/transactions': typeof AuthedTransactionsIndexRoute
  '/lottery/bousai/callback': typeof AuthedLotteryBousaiCallbackRoute
  '/lottery/bousai/mock': typeof AuthedLotteryBousaiMockRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authed': typeof AuthedRouteWithChildren
  '/_authed/about-campaign': typeof AuthedAboutCampaignRoute
  '/s/$storeId': typeof SStoreIdRoute
  '/_authed/': typeof AuthedIndexRoute
  '/closed/': typeof ClosedIndexRoute
  '/_authed/invitation/apply': typeof AuthedInvitationApplyRoute
  '/_authed/invitation/detail': typeof AuthedInvitationDetailRoute
  '/_authed/lottery/apply': typeof AuthedLotteryApplyRoute
  '/_authed/lottery/processing': typeof AuthedLotteryProcessingRoute
  '/_authed/lottery/result': typeof AuthedLotteryResultRoute
  '/_authed/pay/$storeId': typeof AuthedPayStoreIdRoute
  '/_authed/stores/$storeId': typeof AuthedStoresStoreIdRoute
  '/_authed/transactions/$groupId': typeof AuthedTransactionsGroupIdRoute
  '/_authed/i/': typeof AuthedIIndexRoute
  '/_authed/invitation/': typeof AuthedInvitationIndexRoute
  '/_authed/stores/': typeof AuthedStoresIndexRoute
  '/_authed/transactions/': typeof AuthedTransactionsIndexRoute
  '/_authed/lottery/bousai/callback': typeof AuthedLotteryBousaiCallbackRoute
  '/_authed/lottery/bousai/mock': typeof AuthedLotteryBousaiMockRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/about-campaign'
    | '/s/$storeId'
    | '/'
    | '/closed'
    | '/invitation/apply'
    | '/invitation/detail'
    | '/lottery/apply'
    | '/lottery/processing'
    | '/lottery/result'
    | '/pay/$storeId'
    | '/stores/$storeId'
    | '/transactions/$groupId'
    | '/i'
    | '/invitation'
    | '/stores'
    | '/transactions'
    | '/lottery/bousai/callback'
    | '/lottery/bousai/mock'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/about-campaign'
    | '/s/$storeId'
    | '/'
    | '/closed'
    | '/invitation/apply'
    | '/invitation/detail'
    | '/lottery/apply'
    | '/lottery/processing'
    | '/lottery/result'
    | '/pay/$storeId'
    | '/stores/$storeId'
    | '/transactions/$groupId'
    | '/i'
    | '/invitation'
    | '/stores'
    | '/transactions'
    | '/lottery/bousai/callback'
    | '/lottery/bousai/mock'
  id:
    | '__root__'
    | '/_authed'
    | '/_authed/about-campaign'
    | '/s/$storeId'
    | '/_authed/'
    | '/closed/'
    | '/_authed/invitation/apply'
    | '/_authed/invitation/detail'
    | '/_authed/lottery/apply'
    | '/_authed/lottery/processing'
    | '/_authed/lottery/result'
    | '/_authed/pay/$storeId'
    | '/_authed/stores/$storeId'
    | '/_authed/transactions/$groupId'
    | '/_authed/i/'
    | '/_authed/invitation/'
    | '/_authed/stores/'
    | '/_authed/transactions/'
    | '/_authed/lottery/bousai/callback'
    | '/_authed/lottery/bousai/mock'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthedRoute: typeof AuthedRouteWithChildren
  SStoreIdRoute: typeof SStoreIdRoute
  ClosedIndexRoute: typeof ClosedIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthedRoute: AuthedRouteWithChildren,
  SStoreIdRoute: SStoreIdRoute,
  ClosedIndexRoute: ClosedIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authed",
        "/s/$storeId",
        "/closed/"
      ]
    },
    "/_authed": {
      "filePath": "_authed.tsx",
      "children": [
        "/_authed/about-campaign",
        "/_authed/",
        "/_authed/invitation/apply",
        "/_authed/invitation/detail",
        "/_authed/lottery/apply",
        "/_authed/lottery/processing",
        "/_authed/lottery/result",
        "/_authed/pay/$storeId",
        "/_authed/stores/$storeId",
        "/_authed/transactions/$groupId",
        "/_authed/i/",
        "/_authed/invitation/",
        "/_authed/stores/",
        "/_authed/transactions/",
        "/_authed/lottery/bousai/callback",
        "/_authed/lottery/bousai/mock"
      ]
    },
    "/_authed/about-campaign": {
      "filePath": "_authed/about-campaign.tsx",
      "parent": "/_authed"
    },
    "/s/$storeId": {
      "filePath": "s/$storeId.tsx"
    },
    "/_authed/": {
      "filePath": "_authed/index.tsx",
      "parent": "/_authed"
    },
    "/closed/": {
      "filePath": "closed/index.tsx"
    },
    "/_authed/invitation/apply": {
      "filePath": "_authed/invitation/apply.tsx",
      "parent": "/_authed"
    },
    "/_authed/invitation/detail": {
      "filePath": "_authed/invitation/detail.tsx",
      "parent": "/_authed"
    },
    "/_authed/lottery/apply": {
      "filePath": "_authed/lottery/apply.tsx",
      "parent": "/_authed"
    },
    "/_authed/lottery/processing": {
      "filePath": "_authed/lottery/processing.tsx",
      "parent": "/_authed"
    },
    "/_authed/lottery/result": {
      "filePath": "_authed/lottery/result.tsx",
      "parent": "/_authed"
    },
    "/_authed/pay/$storeId": {
      "filePath": "_authed/pay/$storeId.tsx",
      "parent": "/_authed"
    },
    "/_authed/stores/$storeId": {
      "filePath": "_authed/stores/$storeId.tsx",
      "parent": "/_authed"
    },
    "/_authed/transactions/$groupId": {
      "filePath": "_authed/transactions/$groupId.tsx",
      "parent": "/_authed"
    },
    "/_authed/i/": {
      "filePath": "_authed/i/index.tsx",
      "parent": "/_authed"
    },
    "/_authed/invitation/": {
      "filePath": "_authed/invitation/index.tsx",
      "parent": "/_authed"
    },
    "/_authed/stores/": {
      "filePath": "_authed/stores/index.tsx",
      "parent": "/_authed"
    },
    "/_authed/transactions/": {
      "filePath": "_authed/transactions/index.tsx",
      "parent": "/_authed"
    },
    "/_authed/lottery/bousai/callback": {
      "filePath": "_authed/lottery/bousai.callback.tsx",
      "parent": "/_authed"
    },
    "/_authed/lottery/bousai/mock": {
      "filePath": "_authed/lottery/bousai.mock.tsx",
      "parent": "/_authed"
    }
  }
}
ROUTE_MANIFEST_END */
