import { openQrScanner } from "@pocketsign/in-app-sdk";
import { Link } from "@tanstack/react-router";
import { useCallback } from "react";
import Article from "~icons/material-symbols/article-outline";
import HomeFill from "~icons/material-symbols/home";
import QrCodeScanner from "~icons/material-symbols/qr-code-scanner";
import StoreFront from "~icons/material-symbols/storefront-outline";
import { type Styles, css } from "../../styled-system/css";
import { useFooter } from "../contexts/footer-context";
import { sdk } from "../libs/sdk";

const navItemCss = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textDecoration: "none",
  color: "text.primary",
  fontSize: "12px",
  pt: "8px",
  pb: "calc(env(safe-area-inset-bottom, 16px) + 8px)",
  "&.active": {
    color: "text.accentPrimary",
    fontWeight: 700,
  },
});

const navIconWrapperCss = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  rounded: "2xl",
  w: "56px",
  h: "32px",
  ".active &": {
    color: "text.accentPrimary",
    fontWeight: 700,
  },
});

const navIconStyle: Styles = {
  mx: "auto",
  width: "24px",
  height: "24px",
};

const navTextCss = css({
  mt: "4px",
});

export default function Footer() {
  const { disabled } = useFooter();

  const scan = useCallback(() => {
    openQrScanner(sdk);
  }, []);

  if (disabled) {
    return null;
  }

  return (
    <footer
      className={css({
        backgroundColor: "#FFF",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      })}
    >
      <Link to="/" className={navItemCss}>
        <div className={navIconWrapperCss}>
          <HomeFill className={css(navIconStyle)} />
        </div>
        <div className={navTextCss}>ホーム</div>
      </Link>
      <button type="button" className={navItemCss} onClick={scan}>
        <div className={navIconWrapperCss}>
          <QrCodeScanner className={css(navIconStyle)} />
        </div>
        <div className={navTextCss}>読み取り</div>
      </button>
      <Link to="/transactions" className={navItemCss}>
        <div className={navIconWrapperCss}>
          <Article className={css(navIconStyle)} />
        </div>
        <div className={navTextCss}>取引履歴</div>
      </Link>
      <Link to="/stores" className={navItemCss}>
        <div className={navIconWrapperCss}>
          <StoreFront className={css(navIconStyle)} />
        </div>
        <div className={navTextCss}>店舗リスト</div>
      </Link>
    </footer>
  );
}
