import { Link, createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import ChevronRight from "~icons/material-symbols/chevron-right";
import Close from "~icons/material-symbols/close";
import LocationOn from "~icons/material-symbols/location-on";
import Search from "~icons/material-symbols/search";
import StoreFront from "~icons/material-symbols/storefront-outline";
import Tune from "~icons/material-symbols/tune";
import { type Styles, css } from "../../../../styled-system/css";
import Header from "../../../components/header";
import { getCityAddressWithCache } from "../../../libs/sdk";
import { trimPrefecture } from "../../../libs/utils";

const selectButtonStyle: Styles = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
  bg: "white",
  rounded: "full",
  py: "8px",
  px: "16px",
  fontSize: "14px",
  border: "2px solid",
  borderColor: "border.primary",
  color: "text.primary",
  flexShrink: 0,
  "& svg": {
    width: "18px",
    height: "18px",
  },
  "&.active": {
    color: "text.accentPrimary",
    borderColor: "border.accentPrimary",
    bg: "surface.accentPrimaryLight",
  },
};

export const Route = createFileRoute("/_authed/stores/")({
  loader: async ({ context: { client, tenant } }) => {
    const response = tenant.feature.storeList
      ? await client.getStores({})
      : {
          stores: [],
        };

    const { categories } = await client.getCategories({});
    const { cities } = await client.getAllCities({});

    const defaultCurrentCity =
      tenant.slug === "marumori" ? "宮城県伊具郡丸森町" : "宮城県仙台市青葉区";

    const cityAddressResource = await getCityAddressWithCache();

    let currentCity = defaultCurrentCity;

    if (cityAddressResource && cities.includes(cityAddressResource)) {
      currentCity = cityAddressResource;
    }

    return {
      stores: response.stores,
      categories,
      currentCity,
      cities,
      tenant,
    };
  },

  component: Stores,
});

type SearchModalProps = {
  cities: string[];
  categories: string[];
  isOpen: boolean;
  onClose: () => void;
  shouldFocusInput: boolean;
  selectedCities: string[];
  onCityChange: (cities: string[]) => void;
  selectedCategories: string[];
  onCategoryChange: (categories: string[]) => void;
};

function SearchModal({
  cities,
  categories,
  isOpen,
  onClose,
  shouldFocusInput,
  selectedCities,
  onCityChange,
  selectedCategories,
  onCategoryChange,
}: SearchModalProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen && shouldFocusInput && inputRef.current) {
      inputRef.current.focus();

      // iOSにてフォーカス時にページがスクロールされるのを防ぐ
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    }
  }, [isOpen, shouldFocusInput]);

  if (!isOpen) return null;

  const handleCityChange = (city: string) => {
    const newSelectedCities = selectedCities.includes(city)
      ? selectedCities.filter((c) => c !== city)
      : [...selectedCities, city];
    onCityChange(newSelectedCities);
  };

  const handleCategoryChange = (category: string) => {
    const newSelectedCategories = selectedCategories.includes(category)
      ? selectedCategories.filter((c) => c !== category)
      : [...selectedCategories, category];
    onCategoryChange(newSelectedCategories);
  };

  return (
    <div
      className={css({
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
        animation: "slide-up 0.2s ease-out",
        overflowY: "auto",
      })}
    >
      <div
        className={css({
          mt: "16px",
          bg: "background.background",
          rounded: "xl",
          minHeight: "100%",
          borderTop: "1px solid",
          borderColor: "border.secondary",
        })}
      >
        <header
          className={css({
            h: "48px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            position: "sticky",
            top: 0,
            bg: "background.background",
            zIndex: 1,
            color: "text.primary",
          })}
        >
          <h1
            className={css({
              color: "text.primary",
              fontWeight: "500",
              fontSize: "18px",
            })}
          >
            取扱店舗を探す
          </h1>
          <button
            type="button"
            className={css({
              position: "absolute",
              right: 0,
              top: 0,
              height: "48px",
              width: "48px",
              pr: "16px",
              textAlign: "center",
            })}
            onClick={onClose}
          >
            <Close className={css({ width: "24px", height: "24px" })} />
          </button>
        </header>
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            pt: "16px",
            pb: "calc(env(safe-area-inset-bottom, 16px) + 128px)",
          })}
        >
          <div
            className={css({
              display: "flex",
              gap: "16px",
              px: "16px",
            })}
          >
            <input
              ref={inputRef}
              type="text"
              placeholder="店舗名で探す"
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "8px",
                bg: "white",
                rounded: "xl",
                py: "8px",
                px: "16px",
                flexGrow: 1,
                color: "text.secondary",
                border: "1px solid",
                borderColor: "border.secondary",
              })}
            />

            <button
              type="button"
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "8px",
                color: "text.secondary",
              })}
            >
              クリア
            </button>
          </div>

          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              px: "16px",
            })}
          >
            <h3>カテゴリ選択</h3>
            <div
              className={css({
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
              })}
            >
              {categories.map((category) => (
                <button
                  type="button"
                  className={clsx(
                    css(selectButtonStyle),
                    selectedCategories.includes(category) && "active",
                  )}
                  key={category}
                  onClick={() => handleCategoryChange(category)}
                >
                  <span>{category}</span>
                </button>
              ))}
            </div>
          </div>

          {cities.length > 1 && (
            <>
              <hr
                className={css({
                  border: "1px solid",
                  borderColor: "border.secondary",
                })}
              />
              <div
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  px: "16px",
                })}
              >
                <h3>エリアで絞る</h3>
                <div
                  className={css({
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                  })}
                >
                  {cities.map((city) => (
                    <label
                      key={city}
                      className={css({
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        cursor: "pointer",
                        width: "calc(50% - 4px)",
                      })}
                    >
                      <input
                        type="checkbox"
                        value={city}
                        checked={selectedCities.includes(city)}
                        onChange={() => handleCityChange(city)}
                      />
                      <span>{trimPrefecture(city)}</span>
                    </label>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className={css({
          bg: "white",
          position: "fixed",
          bottom: 0,
          width: "100%",
          pt: "16px",
          pb: "calc(env(safe-area-inset-bottom, 16px) + 16px)",
          px: "16px",
          display: "flex",
          justifyContent: "center",
          borderTop: "1px solid",
          borderColor: "border.secondary",
        })}
      >
        <button
          type="button"
          className={css({
            bg: "surface.accentPrimary",
            color: "white",
            rounded: "xl",
            py: "8px",
            px: "16px",
            width: "100%",
          })}
          onClick={() => {
            onClose();
          }}
        >
          検索する
        </button>
      </div>
    </div>
  );
}

function Stores() {
  const { stores, categories, cities, currentCity, tenant } =
    Route.useLoaderData();
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [shouldFocusInput, setShouldFocusInput] = useState(false);
  const [selectedCities, setSelectedCities] = useState<string[]>([currentCity]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const openSearchModal = (focusInput: boolean) => {
    if (tenant.feature.storeList) {
      setIsSearchModalOpen(true);
      setShouldFocusInput(focusInput);
    }
  };

  const filteredStores = stores.filter((store) => {
    if (selectedCategories.length === 0) {
      return selectedCities.includes(store.city ?? "");
    }

    return (
      selectedCities.includes(store.city ?? "") &&
      selectedCategories.includes(store.category)
    );
  });

  return (
    <>
      <Header title="取り扱い店舗" />

      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          pt: "16px",
          pb: "48px",
        })}
      >
        {!tenant.feature.storeList && (
          <div
            className={css({
              display: "flex",
              gap: "16px",
              px: "16px",
            })}
          >
            <button
              type="button"
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "8px",
                bg: "surface.disable",
                rounded: "xl",
                py: "8px",
                px: "16px",
                flexGrow: 1,
                color: "text.disable",
                border: "1px solid",
                borderColor: "border.disable",
              })}
              onClick={() => openSearchModal(true)}
            >
              <Search
                className={css({
                  width: "20px",
                  height: "20px",
                })}
              />
              <span>店舗名で探す</span>
            </button>
          </div>
        )}
        {tenant.feature.storeList && (
          <div
            className={css({
              display: "flex",
              gap: "16px",
              px: "16px",
            })}
          >
            <button
              type="button"
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "8px",
                bg: "white",
                rounded: "xl",
                py: "8px",
                px: "16px",
                flexGrow: 1,
                color: "text.secondary",
                border: "1px solid",
                borderColor: "border.secondary",
              })}
              onClick={() => openSearchModal(true)}
            >
              <Search
                className={css({
                  width: "20px",
                  height: "20px",
                })}
              />
              <span>店舗名で探す</span>
            </button>

            <button
              type="button"
              className={css({
                display: "flex",
                alignItems: "center",
                gap: "8px",
                color: "text.secondary",
              })}
              onClick={() => openSearchModal(false)}
            >
              <Tune
                className={css({
                  width: "24px",
                  height: "24px",
                })}
              />
            </button>
          </div>
        )}

        <div
          className={css({
            display: "flex",
            gap: "8px",
            px: "16px",
            overflowX: "auto",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          })}
        >
          {cities.length > 1 && (
            <button
              type="button"
              className={clsx(css(selectButtonStyle), "active")}
              onClick={() => openSearchModal(false)}
            >
              <LocationOn />
              <span>{trimPrefecture(currentCity)}</span>
            </button>
          )}

          {categories.map((category) => (
            <button
              type="button"
              className={clsx(
                css(selectButtonStyle),
                selectedCategories.includes(category) && "active",
              )}
              key={category}
              onClick={() => {
                const newSelectedCategories = selectedCategories.includes(
                  category,
                )
                  ? selectedCategories.filter((c) => c !== category)
                  : [...selectedCategories, category];
                setSelectedCategories(newSelectedCategories);
              }}
            >
              <span>{category}</span>
            </button>
          ))}
        </div>

        <div
          className={css({
            px: "16px",
            fontSize: "14px",
            color: "text.secondary",
          })}
        >
          全 {filteredStores.length} 件
        </div>

        {!tenant.feature.storeList && (
          <div
            className={css({
              color: "text.secondary",
              textAlign: "center",
              py: "16px",
            })}
          >
            <p>準備中です</p>
            <p>しばらくお待ちください</p>
          </div>
        )}

        {tenant.feature.storeList && (
          <div
            className={css({
              px: "16px",
              maxWidth: "100%",
            })}
          >
            <div
              className={css({
                bg: "white",
                rounded: "xl",
                maxWidth: "100%",
              })}
            >
              {filteredStores.map((store, index) => (
                <Link
                  to={`/stores/${store.id}`}
                  key={store.id}
                  className={css({
                    display: "flex",
                    alignItems: "center",
                    pl: "16px",
                    maxWidth: "100%",
                    gap: "16px",
                  })}
                >
                  <div
                    className={css({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    })}
                  >
                    <StoreFront
                      className={css({
                        width: "24px",
                        height: "24px",
                      })}
                    />
                  </div>
                  <div
                    className={css({
                      flexGrow: 1,
                      display: "flex",
                      borderBottom:
                        index === stores.length - 1 ? "none" : "1px solid",
                      py: "16px",
                      maxWidth: "calc(100% - 40px)",
                      borderColor: "border.secondary",
                    })}
                  >
                    <div
                      className={css({
                        display: "flex",
                        flexGrow: 1,
                        minWidth: 0,
                        flexDirection: "column",
                      })}
                    >
                      <h3
                        className={css({
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        })}
                      >
                        {store.name}
                      </h3>
                      <p
                        className={css({
                          color: "text.tertiary",
                          fontSize: "12px",
                        })}
                      >
                        {store.category}
                      </p>
                    </div>

                    <div
                      className={css({
                        color: "text.tertiary",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        pr: "8px",
                      })}
                    >
                      <ChevronRight strokeWidth={1} />
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>

      <SearchModal
        cities={cities}
        categories={categories}
        isOpen={isSearchModalOpen}
        onClose={() => setIsSearchModalOpen(false)}
        shouldFocusInput={shouldFocusInput}
        selectedCities={selectedCities}
        onCityChange={setSelectedCities}
        selectedCategories={selectedCategories}
        onCategoryChange={setSelectedCategories}
      />
    </>
  );
}
